import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import DynamicIcon from "../DynamicIcon";

export interface INotificationProps {
    children: React.ReactNode;
    onChange?: () => void;
    type?: "coordination" | "signed" | "graph" | "err" | "info" | "err_not_margin" | "red" | "blue" | "grey";
    icon?: string;
    style?: React.CSSProperties;
}

const Notification = (props: INotificationProps): ReactElement => {
    const { children, onChange = () => null, type, icon, style } = props;
    const onchange = () => {
        onChange();
    };

    return (
        <div style={style} onChange={onchange} className={clsx(styles["tag"], styles[`tag_${type}`])}>
            <div className={clsx(icon && styles[`tag_icon`])}>{icon ? <DynamicIcon name={icon} /> : null}</div>
            {children}
        </div>
    );
};

export default Notification;
