import React, { ReactElement } from "react";
import styles from "./index.module.scss";
import VacationCheckList2 from "./components/VacationCheckList/index2";
import { IVacationCheck2 } from "../../../../typec/IVacations";

const VacationCheck2 = (props: IVacationCheck2): ReactElement => {
    const {
        numberVacation,
        statementDay,
        nameStaff,
        position,
        subdivision,
        supervisor,
        typeVacation,
        startDay,
        finishDay,
        quantityDay,
        quantitySposob,
        periodArr,
        commentary,
        dataUvolneniya,
        prichinaUvolneniya,
        commentaryBoss,
        dataUvolneniyaBoss,
        prichinaUvolneniyaBoss,
        kolichestvoEkzemplyarov,
        mestoVydachiSpravki,
        opisanieMestaVydachiSpravki,
        periodSpravki,
        sposobPolucheniyaSpravki,
        vidSpravki,
        statusCommentary,
        vidPersonalnyhDannyh,
        prichinaIzmeneniyaPersonalnyhDannyh,
        coordination,
        itemDetails,
        status,
        disableNo = false,
        writeComment,
        writeCommentEdit,
        nameChildren,
        photoArr,
        disableVoid = false,
        nameEditItemComment,
        valueComment,
        onBlur,
        onFocus,
        commentaryStaff,
        photoAdd,
        photoConfirmation,
        photoError,
        cardNumber,
        dataUvolneniyaZhelayemaya,
        sickLeave,
        TotalSum,
    } = props;
    return (
        <div className={styles.vac_ch}>
            {disableNo ? (
                <p className={styles.vac_ch_title}>
                    Заявление на {typeVacation} от {statementDay}
                </p>
            ) : (
                <p className={styles.vac_ch_title}>
                    Заявление на {typeVacation} <br /> №{numberVacation} от {statementDay}
                </p>
            )}
            <div className={styles.vac_ch_list}>
                <VacationCheckList2
                    fullName={nameStaff}
                    period={`C ${startDay} по ${finishDay}`}
                    position={position}
                    quantityDay={quantityDay}
                    quantitySposob={quantitySposob}
                    subdivision={subdivision}
                    supervisor={supervisor}
                    typeVacation={typeVacation}
                    startDay={startDay}
                    periodArr={periodArr}
                    commentary={commentary}
                    dataUvolneniya={dataUvolneniya}
                    prichinaUvolneniya={prichinaUvolneniya}
                    commentaryBoss={commentaryBoss}
                    dataUvolneniyaBoss={dataUvolneniyaBoss}
                    prichinaUvolneniyaBoss={prichinaUvolneniyaBoss}
                    kolichestvoEkzemplyarov={kolichestvoEkzemplyarov}
                    mestoVydachiSpravki={mestoVydachiSpravki}
                    opisanieMestaVydachiSpravki={opisanieMestaVydachiSpravki}
                    periodSpravki={periodSpravki}
                    sposobPolucheniyaSpravki={sposobPolucheniyaSpravki}
                    vidSpravki={vidSpravki}
                    statusCommentary={statusCommentary}
                    vidPersonalnyhDannyh={vidPersonalnyhDannyh}
                    prichinaIzmeneniyaPersonalnyhDannyh={prichinaIzmeneniyaPersonalnyhDannyh}
                    coordination={coordination}
                    itemDetails={itemDetails}
                    status={status}
                    writeComment={writeComment}
                    writeCommentEdit={writeCommentEdit}
                    nameChildren={nameChildren}
                    photoArr={photoArr}
                    nameEditItemComment={nameEditItemComment}
                    valueComment={valueComment}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    commentaryStaff={commentaryStaff}
                    photoAdd={photoAdd}
                    photoConfirmation={photoConfirmation}
                    photoError={photoError}
                    cardNumber={cardNumber}
                    dataUvolneniyaZhelayemaya={dataUvolneniyaZhelayemaya}
                    sickLeave={sickLeave}
                    TotalSum={TotalSum}
                />
            </div>
            {disableVoid ? null : <div className={styles.vac_ch_void} />}
        </div>
    );
};

export default VacationCheck2;
