import React, { useState, useEffect, useContext } from "react";
import styles from "./index.module.scss";
import { NavigationContext } from "../../../template";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { InputCalendar, InputDropDown, InputError, InputTextArea } from "../../../components/Inputs";
import clsx from "clsx";
import SlideScreen from "../../../components/SlideScreen";
import Button from "../../../components/Button";

export interface IQuestionsList {
    homeScreenData: any;
    actionElement?: () => void;
    reasons: any;
    addData: (key, element) => void;
    error?: any;
    clearError?: () => void;
    minDate?: Date;
    current?: any;
}

const Submit = (props) => {
    const [load, setLoad] = useState(false);
    const [cause, setCause] = useState(false);
    const context = useContext(NavigationContext);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };
    const cancel = () => {
        setCause(false);
    };

    const [searchParam, setSearchParam] = useSearchParams();

    const redirect = (id) => {
        setSearchParam({ app_id: id, forward_to: "approve" });
    };

    const { actionElement = () => null, reasons, current, addData, homeScreenData, clearError = () => null, disable, type, getLoad } = props;
    const [data, setData] = useState(homeScreenData);
    const [error, setError] = useState("");

    const createError = (str) => {
        setError(str);
    };
    const clearErrorInter = () => {
        setError("");
    };

    const editData = (key, element) => {
        setData({
            ...data,
            [key]: element,
        });
        clearError();
        clearErrorInter();
    };
    // const [data, setData] = useState({
    //     homeScreen: {
    //         cause: "",
    //         details: "",
    //         dateDismissal: "",
    //     },
    //     questionsList: undefined,
    //     address: "",
    // });
    // const saveData = (key, element) => {
    //     addData(key, element);
    //     actionElement();
    // };
    // const dateAsArray = current.Date.split(".");
    // console.log(new Date(`${dateAsArray[1]}.${dateAsArray[0]}.${dateAsArray[2]}`));

    const Approve = () => {
        clearErrorInter();
        setLoad(true);
        fetch("/dev/vacation_list_boss_new/", {
            method: "PUT",
            body: JSON.stringify({
                IDApp: props.current.IDApp,
                Result: 1,
                Comment: type == "Увольнение" ? homeScreenData.details : "",
                DismDate: type == "Увольнение" ? homeScreenData.dateDismissal?.replace(".2", ".202") : "",
                DismReason: type == "Увольнение" ? homeScreenData.cause : "",
                DismAgreeDistWork: type == "Увольнение" ? (homeScreenData.dismBool != undefined ? homeScreenData.dismBool : false) : "",
                ChecksItems: props.newItems,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    if (getLoad) getLoad();
                    redirect(props.current.IDApp);
                } else {
                    createError(json.Error);
                }
                setLoad(false);
            })
            .catch((e) => {
                console.error(e);
                setLoad(false);
            });
    };
    const Reject = () => {
        clearErrorInter();
        setLoad(true);
        console.log(props.current.IDApp);
        fetch("/dev/vacation_list_boss_new/", {
            method: "PUT",
            body: JSON.stringify({
                IDApp: props.current.IDApp,
                Result: 0,
                Comment: data.details,
                DismDate: "",
                DismReason: "",
                DismAgreeDistWork: "",
                ChecksItems: props.newItems,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    if (getLoad) getLoad();
                    redirect(props.current.IDApp);
                } else {
                    createError(json.Error);
                }
                setLoad(false);
                setCause(false);
            })
            .catch((e) => {
                console.error(e);
                setLoad(false);
                setCause(false);
            });
    };
    const CauseReject = () => {
        setCause(true);
        // console.log(props.current.IDApp);
    };

    return (
        <>
            <SlideScreen altitudeLevel="service">
                {error ? <InputError titleText={error} /> : null}
                <Button load={load} onClick={Approve} disabled={disable} type="save_not_margin">
                    Согласовать
                </Button>
                <Button onClick={CauseReject} type="cancel_grey_red">
                    Отклонить
                </Button>
            </SlideScreen>
            {cause && (
                <div className={styles.cancel}>
                    <div className={styles.cancel_area}>
                        <div className={styles.cancel_area_title}>Укажите причину отказа</div>
                        <InputTextArea
                            // errorMessage={error.Error}
                            value={data.details}
                            editData={editData}
                            name="details"
                            type="area_middle"
                            // titleText="Подробности"
                            placeholder="Опишите подробнее причину..."
                            counterView={true}
                            counterValue="400"
                            style={{ marginBottom: "24px" }}
                        />
                    </div>
                    <SlideScreen altitudeLevel="service">
                        {error ? <InputError titleText={error} /> : null}
                        <Button load={load} onClick={Reject} disabled={!data.details} type="save_not_margin">
                            Отклонить заявление
                        </Button>
                        <Button onClick={cancel} type="cancel_grey">
                            Отмена
                        </Button>
                    </SlideScreen>
                </div>
            )}
        </>
    );
};

export default Submit;
