import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Navigation from "../../components/Navigation";
import { NavigationContext } from "../../template";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Filter from "../../icons/filter.svg";
import MenuFiltr from "../RequestsList/MenuFiltr";
import NewRequest from "../RequestsList/NewRequest";
import FiltrComponent from "../RequestsList/FilterComponent";
import ListItem from "../RequestsList/ListItem";
import List from "../RequestsList/List";
import ListNull from "../RequestsList/ListNull";
import menu from "../../components/Menu/Menu";
import Submit from "./Submit";
import Notification from "../../components/Notification";

const RequestsList = (props): ReactElement => {
    const context = useContext(NavigationContext);
    const [load, setLoad] = useState(false);
    const [reqNull, setReqNull] = useState(true);
    const [loadList, setLoadList] = useState(false);
    const [listEmpty, setListEmpty] = useState(false);
    const [rawData, setRawData] = useState<Array<any>>([]);
    const [jsonData, setJsonData] = useState<Array<any>>([]);
    const [currentFilter, setCurrentFilter] = useState<Array<string>>(["active"]);
    const navigate = useNavigate();
    const prev = () => {
        navigate("/");
    };
    const goToMain = () => {
        prev();
        context.goToMain();
    };
    useEffect(() => {
        if (!load) {
            setLoad(true);
        }
    }, []);

    // const filterAdd = (filterName: string) => {
    //     setCurrentFilter([...currentFilter, filterName]);
    // };
    // const filterDelete = (filterName) => {
    //     const newArr = currentFilter.filter((str) => str !== filterName);
    //     setCurrentFilter(newArr);
    // };
    const filterAction = (arr: Array<string>) => {
        setCurrentFilter(arr);
    };

    // useEffect(() => {
    //     if (currentFilter.length) {
    //         const newList: Array<any> = [];
    //         if (currentFilter.includes("В работе")) {
    //             newList.push(...rawData.filter((el) => el.ApprDecl == 0));
    //         }
    //         if (currentFilter.includes("Завершено")) {
    //             newList.push(...rawData.filter((el) => el.ApprDecl == 1));
    //         }
    //         if (currentFilter.includes("Отклонено или отменено")) {
    //             newList.push(...rawData.filter((el) => el.ApprDecl == -1));
    //         }
    //         setJsonData(newList);
    //         console.log(currentFilter);
    //     }
    // }, [currentFilter]);

    useEffect(() => {
        // if (load) {
        setLoadList(false);
        fetch(`/dev/vacation_list_boss_new?filtr=${currentFilter.join("+")}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res == true) {
                    setListEmpty(json.list_empty);
                    setLoadList(true);
                    if (json.list.length > 0) {
                        setReqNull(false);
                    } else {
                        setReqNull(true);
                    }
                    setLoad(false);
                    setJsonData(json.list);
                    setRawData(json.list);
                } else {
                    setLoad(false);
                    setLoadList(false);
                }
            })
            .catch((e) => {
                console.error(e);
                setLoad(false);
                setLoadList(false);
            });
        // }
    }, [currentFilter]);

    const getLoad = () => {
        setLoad(true);
    };
    return (
        <>
            {load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <MenuFiltr isApprovePage={true} filterAction={filterAction} data={jsonData} menu={menu}></MenuFiltr>
                    {/* {reqNull ? (
                        // <ListNull></ListNull>
                        <div className={styles.notification}>
                            <Notification
                                type="info"
                                icon="info"
                                children={`"Вы согласовали все заявления. Если хотите просмотреть все заявления, нажмите "Фильтр" и проставьте нужные виды"`}
                            ></Notification>
                        </div>
                    ) : (
                        <div className={styles.list}>
                            <List getLoad={getLoad} isApprovePage={true} data={jsonData}></List>
                        </div>
                    )} */}
                    {loadList ? (
                        <>
                            {reqNull && currentFilter[0] === "active" ? (
                                <div className={styles.notification}>
                                    <Notification
                                        type="info"
                                        icon="info"
                                        children={`Вы согласовали все заявления. Если хотите просмотреть все заявления, нажмите "Фильтр" и проставьте нужные виды`}
                                    ></Notification>
                                </div>
                            ) : reqNull && currentFilter[0] !== "active" ? (
                                <div className={styles.notification}>
                                    <Notification type="info" icon="info" children={`Таких заявлений нет. Измените "Фильтр"`}></Notification>
                                </div>
                            ) : (
                                <div className={styles.list}>
                                    <List getLoad={getLoad} isApprovePage={true} data={jsonData}></List>
                                </div>
                            )}
                        </>
                    ) : (
                        <Loader></Loader>
                    )}
                </>
            )}
        </>
    );
};

export default RequestsList;
